

import React, { useContext, useEffect, useRef, useState } from 'react'
import { GlobalContext } from '../GlobalContext'
import CloudformationOutputs from '../CloudformationOutputs.json'
import HeaderDetails from '../shared-components/HeaderDetails'
import Spinner from '../ui-elements/Spinner'
import RedAlert from '../ui-elements/RedAlert'
import { useNavigate, useParams } from 'react-router-dom'
import { getChecklistProgress } from '../utils/getChecklistProgress'
import FooterNavButtons from '../shared-components/FooterNavButtons'
import Checkbox from '../formfields/Checkbox'
import SignatureCanvas from './SignatureCanvas'
import Modal from '../ui-elements/Modal'
import { subscribe } from '../utils/pubsub'
import { b64toBlob } from '../utils/b64toBlob'
import { getDate } from '../utils/getDate'
import FormatQuestionAnsweredBy from './FormatQuestionAnsweredBy'

type Props = {
}

function CompleteChecklist({
}: Props) {

    const {
        loggedIn,
        setShowModal,
        tableData,
        userData,
        sendMessageToWebsocket,
        currentOrganisation,
        isSignoffRequired,
        checkPeriodIdsForActiveCheckists
    } = useContext(GlobalContext)
    const navigate = useNavigate()
    const { checkPeriodId } = useParams()
    // const [thisChecklist, setThisChecklist] = useState<ObjectStringKeyAnyValue | null>(null)
    // const [thisCheckPeriodSignoff, setThisCheckPeriodSignoff] = useState<ObjectStringKeyAnyValue | null>(null)
    const [errorMessage, setErrorMessage] = useState<string | null>(null)
    // const [checklistComplete, setChecklistComplete] = useState(false)
    const [confirmSignoff, setConfirmSignff] = useState(false)
    const [signatureCanvasData, setSignatureCanvasData] = useState('')
    const thisCheckPeriod = checkPeriodId && tableData && tableData.ChecklistPeriods && tableData.ChecklistPeriods[checkPeriodId] ? tableData.ChecklistPeriods[checkPeriodId] : null
    const thisChecklist = thisCheckPeriod && thisCheckPeriod.ChecklistId && tableData && tableData.Checklists && tableData.Checklists[thisCheckPeriod.ChecklistId] ? tableData.Checklists[thisCheckPeriod.ChecklistId] : null
    const thisCheckPeriodSignoff = thisCheckPeriod && thisCheckPeriod.Signoff ? thisCheckPeriod.Signoff : null
    const checklistComplete = thisCheckPeriod && thisCheckPeriod.Progress && thisCheckPeriod.Progress.total_answers > 0 && thisCheckPeriod.Progress.completed_answers === thisCheckPeriod.Progress.total_answers ? true : false
    const checklistIncomplete = thisCheckPeriod && thisCheckPeriod.Progress && thisCheckPeriod.Progress.total_answers > 0 && thisCheckPeriod.Progress.completed_answers !== thisCheckPeriod.Progress.total_answers ? true : false

    // useEffect(() => {

    //     if (checkPeriodId && tableData) {
    //         if (
    //             checkPeriodId &&
    //             tableData.Checklists &&
    //             tableData.ChecklistPeriods &&
    //             tableData.ChecklistPeriods[checkPeriodId] &&
    //             tableData.ChecklistPeriods[checkPeriodId]['ChecklistId'] &&
    //             tableData.Checklists[tableData.ChecklistPeriods[checkPeriodId]['ChecklistId']]) {
    //             const currentChecklist = tableData.Checklists[tableData.ChecklistPeriods[checkPeriodId]['ChecklistId']]
    //             setThisChecklist(currentChecklist)
    //             const thisCheckPeriod = tableData.ChecklistPeriods[checkPeriodId]
    //             if (
    //                 thisCheckPeriod &&
    //                 thisCheckPeriod.Progress &&
    //                 thisCheckPeriod.Progress.total_answers > 0 &&
    //                 thisCheckPeriod.Progress.completed_answers === thisCheckPeriod.Progress.total_answers
    //             ) {
    //                 setChecklistComplete(true)
    //             }
    //             else {
    //                 setErrorMessage(`Checklist period ${checkPeriodId} is not complete yet`)
    //                 setChecklistComplete(false)
    //             }
    //         } else {
    //             setErrorMessage(`Checklist period ${checkPeriodId} not found`)
    //         }
    //     }

     

    // }, [tableData, checkPeriodId, checkPeriodIdsForActiveCheckists])

    useEffect(() => {
        if (confirmSignoff && signatureCanvasData) {
            setErrorMessage(null)
        }
    }, [confirmSignoff, signatureCanvasData])


    const handleCheck = (event: any) => {
        setConfirmSignff(event.target.checked)
    }

    const saveSignoff = async () => {
        if (
            !confirmSignoff ||
            !signatureCanvasData
        ) {
            setErrorMessage('Please add your signature and tick the confirmation box')
        }
        else if (
            !checkPeriodId ||
            !tableData.ChecklistPeriods ||
            !tableData.ChecklistPeriods[checkPeriodId]
        ) {
            setErrorMessage('Error - invalid checklist id or check period id')
        } else {
            setShowModal({ "spinner": 'Saving...' })
            setErrorMessage(null)
            const payload: ObjectStringKeyAnyValue = {
                action: "checklistsClientApp",
                subAction: "saveChecklistSignoff",
                checklistPeriodId: checkPeriodId
            }
            // if a user is logged in as a guest, add their name to the data
            if (loggedIn === 'guest') {
                payload['GuestUserName'] = userData.name
            }
            sendMessageToWebsocket(JSON.stringify(payload))

            // upload the signature
            const timestamp = `${Date.now()}`
            let fileName = `signoff-${checkPeriodId}-${timestamp}.jpg`
            await handleImageUpload(checkPeriodId, signatureCanvasData, fileName, timestamp)
        }
    }



    const handleImageUpload = async (checklistPeriodId: string, dataString: string, fileName: string, timestamp: string) => {
        // console.log('handle image upload')
        const payload = JSON.stringify({
            action: "checklistsClientApp",
            subAction: "requestPresignedUrl",
            fileName: fileName,
            waitingForUploadUrlId: timestamp
        })
        sendMessageToWebsocket(payload)
        const response = await new Promise<void>((resolve, reject) => {
            const unsubscribe = subscribe("returnPresignedUrl", async data => {
                if (data.waitingForUploadUrlId === timestamp) {
                    unsubscribe()
                    const prefix = 'data:image/jpeg;base64,'
                    const fileBody = dataString!.split(prefix)[1]
                    const file = new File([b64toBlob(fileBody)], timestamp, { type: "image/jpeg" })
                    await fetch(data.signedUrl, {
                        method: 'PUT',
                        body: file
                    })
                        .then(response => {
                            // console.log('getting response')
                            // send answer to websocket
                            const payload = JSON.stringify({
                                action: "checklistsClientApp",
                                subAction: "saveSignoffSignature",
                                checklistPeriodId: `${checklistPeriodId}`,
                                ImageFileName: data.imageFileName
                            })
                            sendMessageToWebsocket(payload)

                            // stop spinner once signoff is saved
                            const unsubscribe = subscribe("tableUpdateItemModified", data => {
                                if (data.newRecord && data.newRecord.Id === checklistPeriodId) {
                                    setShowModal(null)
                                    // navigate(`/checklists/${checklistId}`)
                                    unsubscribe()
                                }
                            })

                            return (response)
                        }).catch((error) => {
                            console.error('Error:', error)
                        })
                }
            })
        })
    }





    return <div className={`w-full flex-1 h-full flex flex-col items-center gap-2`}>

        <HeaderDetails titleText={thisChecklist ?
            `${thisChecklist.ChecklistName}` :
            `Sign off checklist`}
        />

        {isSignoffRequired && thisChecklist && checklistComplete && !thisCheckPeriodSignoff &&
            <div className={`max-w-3xl w-full px-5 py-5 flex flex-col gap-4 items-center`}>

                <h4 className={`font-righteous text-brandblue text-2xl`}>
                    Sign off checklist</h4>
                <SignatureCanvas
                    setSignatureCanvasData={setSignatureCanvasData}
                />

                <label className={`flex flex-row gap-2 items-start justify-start`}>
                    <input
                        type={`checkbox`}
                        className={`h-6`}
                        onChange={handleCheck}
                        defaultChecked={confirmSignoff}
                    />
                    <p className={``}>
                        I confirm that all the answers are correct</p>
                </label>
                {errorMessage && <RedAlert icon={true} size={'large'}>{errorMessage}</RedAlert>}
            </div>}



        {isSignoffRequired && thisChecklist && thisCheckPeriodSignoff && <div className={`max-w-3xl w-full px-5 py-5 flex flex-col gap-2 items-center text-center`}>
            <h4 className={`font-righteous text-brandblue text-2xl`}>This checklist has been completed:</h4>
            <p className={`text-xs italic`}>By
                <FormatQuestionAnsweredBy questionAnswer={thisCheckPeriodSignoff} /> on {getDate(thisCheckPeriodSignoff.TimeStamp, 'formattedDateAndTime')}
            </p>
            {thisCheckPeriodSignoff.Signature && <img
                src={`https://${CloudformationOutputs.ImageBucketName}.s3.eu-west-1.amazonaws.com/${currentOrganisation}/${thisCheckPeriodSignoff.Signature}`}
                alt={`Signoff signature`}
                className={`mt-4 max-w-64 max-h-64 rounded`}
            />}
        </div>}



        {!tableData && <Spinner><p>Loading...</p></Spinner>}

        {tableData && !thisChecklist && <RedAlert>Checklist not found</RedAlert>}

        {tableData && !isSignoffRequired && <RedAlert>Signoff is not required for this checklist</RedAlert>}

        {tableData && thisChecklist && checklistIncomplete && <RedAlert>Checklist period {checkPeriodId} has not been completed yet</RedAlert>}




        <FooterNavButtons
            backButtonUrl={`${thisChecklist && !thisCheckPeriodSignoff ?
                `/checklists/${checkPeriodId}/` : '/'}`}
            backButtonText={thisCheckPeriodSignoff ? 'Done' : 'Back'}
            nextButtonAction={isSignoffRequired && thisChecklist && checklistComplete && !thisCheckPeriodSignoff ? saveSignoff : null}
            nextButtonText={isSignoffRequired && thisChecklist && checklistComplete && !thisCheckPeriodSignoff ? 'Submit' : ''}
        />

    </div>
}

export default CompleteChecklist