
import React, { useContext, useRef, useEffect, useState } from 'react'
import { GlobalContext } from '../GlobalContext'
import { useNavigate } from 'react-router-dom'
import Button from '../formfields/Button'
import { useVideo } from '../context/video/VideoContext';
import RedAlert from '../ui-elements/RedAlert'
import { checkIfAssetExists } from '../utils/checkIfAssetExists'
import { getAssetIdFromUrl } from '../utils/getAssetIdFromUrl'
import Modal from '../ui-elements/Modal'
import PhotoInstruction from '../shared-components/PhotoInstruction'
import Card from '../ui-elements/Card'
import { getAnswersForThisAssetId } from '../utils/getAnswersForThisAssetId'




type Props = {
  questionId?: string
  checklistId?: string
  questionAnswer: string | null | string[]
  setQuestionAnswer: React.Dispatch<React.SetStateAction<any>>
  defaultValue: null | string | string[]
  assetId: string | null
  answerNote: string | null
  setAnswerNote: React.Dispatch<React.SetStateAction<any>>
  answerPhoto: string | null
  setAnswerPhoto: React.Dispatch<React.SetStateAction<any>>
  isAnswerManuallyEdited: boolean
  setIsAnswerManuallyEdited: React.Dispatch<React.SetStateAction<any>>
  jpegString: string | null
  setJpegString: React.Dispatch<React.SetStateAction<any>>
  assetHasDigitalDisplay: boolean | null
  checkPeriodId?: string,
  setFooterButtons: React.Dispatch<React.SetStateAction<string[]>>
  setQuestionAnswerQRProof: React.Dispatch<React.SetStateAction<any>>
}

function QuestionPhotoAndReading({
  checkPeriodId,
  questionId,
  checklistId,
  questionAnswer,
  setQuestionAnswer,
  defaultValue,
  assetId,
  answerNote,
  setAnswerNote,
  answerPhoto,
  setAnswerPhoto,
  isAnswerManuallyEdited,
  setIsAnswerManuallyEdited,
  jpegString,
  setJpegString,
  assetHasDigitalDisplay,
  setFooterButtons,
  setQuestionAnswerQRProof
}: Props) {

  const {
    tableData,
    isVideoMode,
    setIsVideoMode,
    isVideoPlaying,
    setIsVideoPlaying
  } = useContext(GlobalContext)


  const navigate = useNavigate()
  // const [jpegString, setJpegString] = useState<string | undefined>(undefined)
  const [flowState, setFlowState] = useState<'LookingForQR' | 'QRFound' | 'PhotoTaken'>('LookingForQR')
  const [showReadingDetectedModal, setShowReadingDetectedModal] = useState(false)
  const [showInvalidAssetMessage, setShowInvalidAssetMessage] = useState(false)
  const [showUnexpectedQrCodeModal, setShowUnexpectedQrCodeModal] = useState(false)
  const [showAssetAlreadyChecked, setShowAssetAlreadyChecked] = useState<string | null>(null)
  const [showFoundBanner, setShowFoundBanner] = useState(false)
  const { QRCodesPresent, setQRCodesPresent, setQRCodesSearchOptions, captureAsJPEGString, setExpectedReadingDetails, aiResults, clearCameraStateVariables } = useVideo()
  const defaultQrSearchOptions = {
    'enabled': true,
    'expectedDomains': ['QRWL.ME', 'CHQN.IN'],
    'searchArea': { top: 0, left: 0, width: 1, height: 1 },
    'showSearchArea': false,
    'takeStraightenedPhoto': true//,
//    'attemptAiReading': true
  }

  useEffect(() => {
    if (QRCodesPresent && QRCodesPresent.length > 0) {
      const assetIdFromQRCode = getAssetIdFromUrl(QRCodesPresent[0])
      if (flowState == 'LookingForQR' && assetIdFromQRCode === assetId) {
        setFlowState('QRFound')
        setShowFoundBanner(true)
        setTimeout(() => {
          console.log("Hiding banner")
          setShowFoundBanner(false)}
          , 4000)
        const qrPhotoData = captureAsJPEGString(600) || ''
        setQuestionAnswerQRProof(qrPhotoData)
      }
    }
  }, [QRCodesPresent, flowState, assetId])

  useEffect(() => {
    if (flowState == 'QRFound' && jpegString) {
      setFlowState('PhotoTaken')
    }
    if(flowState == 'PhotoTaken' && jpegString == null) {
      setFlowState('QRFound')

    }
  }, [jpegString, flowState])


  useEffect(() => {
    if (flowState == 'QRFound') {
      setFooterButtons(['takePhoto', 'back'])
    }
    if (flowState == 'PhotoTaken') {
      if(questionAnswer && questionAnswer != '') {
      setFooterButtons(['save', 'saveWithNote', 'retakePhoto', 'back'])
      } else {
        setFooterButtons(['retakePhoto', 'back'])
      }
    }
  }, [flowState, questionAnswer]
  )

  useEffect(() => {
    return () => {
      // code to run when the user navigates away from the page
      console.log("Stopping camera (5)")
      setIsVideoMode(false)
      setIsVideoPlaying(false)
      setJpegString(null)
      setAnswerNote(null)
      // console.log("leaving page")
    }
  }, [])

  useEffect(() => {
    if (showAssetAlreadyChecked) {
      const timeoutId = setTimeout(() => {
        setShowAssetAlreadyChecked(null)
      }, 2000)
      return () => {
        clearTimeout(timeoutId)
      }
    }
  }, [showAssetAlreadyChecked])


  useEffect(() => {
    if (assetId && tableData) {
      // check if asset exists
      if (tableData && tableData['Assets'] && tableData['Assets'][assetId]) {
        const asset = tableData['Assets'][assetId]
        if (asset['DigitsAfterDecimal'] && asset['DigitsAfterDecimal']) {
          console.log(`setting expected reading details. Before: ${asset["DigitsBeforeDecimal"]} after: ${asset["DigitsAfterDecimal"]}`)
          setExpectedReadingDetails(parseInt(asset['DigitsAfterDecimal']), 10)
        } else {
          console.log(`No expected reading details found for asset ${assetId}`)
        }
      }
    }
  }, [assetId, tableData])



  useEffect(() => {
    console.log("Question changed")
    console.log("Setting is video mode to true")
    setIsVideoMode(true)
    setIsVideoPlaying(true)
    setFlowState('LookingForQR')
  }, [questionId])

  useEffect(() => {
    // this handles unrecongised qr codes and directing to qr codes for other assets in the checklist
    let isAssetIdValid = false
    let doesAssetMatch = null
    let letHasQrBeenFound = false
    let assetIdFromDataUrl = null



    if (assetId && tableData && tableData.Assets) {
      // check if asset exists
      if (tableData && checkIfAssetExists(assetId, tableData)) {
        isAssetIdValid = true
      }
      // check if asset from QR code matches asset associated with this question
      if (QRCodesPresent && QRCodesPresent.length > 0) {
        assetIdFromDataUrl = getAssetIdFromUrl(QRCodesPresent[0])
        letHasQrBeenFound = true
        doesAssetMatch = assetIdFromDataUrl === assetId
        console.log(`Asset from QR code: ${assetIdFromDataUrl} asset from question: ${assetId} does asset match? ${doesAssetMatch}`)

      } else {
        letHasQrBeenFound = false
        doesAssetMatch = false
      }


      if (isAssetIdValid) {
        console.log(`has qr been found: ${letHasQrBeenFound} does asset match: ${doesAssetMatch}`)

        setQRCodesSearchOptions(defaultQrSearchOptions)

        if (letHasQrBeenFound && doesAssetMatch === false) {

          const assetIds: ObjectStringKeyAnyValue = getAnswersForThisAssetId(tableData, checkPeriodId)
          const answerForScannedAsset = assetIds && assetIds[assetIdFromDataUrl]

          if (answerForScannedAsset && answerForScannedAsset.answerId) {
            if (answerForScannedAsset['pending'] === true) {
              // if it an asset that hasn't been checked yet,
              // redirect to that question
              navigate(`/checklists/${checkPeriodId}/${answerForScannedAsset.answerId}`)
            } else {
              // if it an asset that has been checked already
              setShowAssetAlreadyChecked(assetIdFromDataUrl)
              setShowUnexpectedQrCodeModal(false)
            }
          } else {
            // if it not part of this checklist at all, show a warning
            setShowUnexpectedQrCodeModal(true)
            setShowAssetAlreadyChecked(null)
          }

        }

      } else {
        // if asset is invalid, show warning message
        setShowInvalidAssetMessage(true)
        console.log("Stopping camera (4)")
        // setIsVideoMode(false)
        // setIsVideoPlaying(false)
      }
    } else {
      setShowInvalidAssetMessage(false)
    }

  }, [QRCodesPresent, tableData, assetId])





  return <div
    className={`max-w-3xl w-full h-full pt-0 pb-8 px-3 flex flex-col gap-2 justify-center  items-center`}
  >
    {flowState == 'LookingForQR' &&
    <>
    <PhotoInstruction>
      <h1>🔍 Scan the QR Code</h1>
    </PhotoInstruction>
    </>
    }








    {!assetId && <RedAlert fullwidth={true}>No asset Id</RedAlert>}
    {showInvalidAssetMessage && <RedAlert fullwidth={true}>Asset ID #{assetId} is invalid</RedAlert>}




    {showAssetAlreadyChecked && <Modal>
      <div className={`flex flex-col gap-4`}>
        <div className={`flex flex-col gap-2 items-center`}>

          <img src={`/icons/green-tick-icon.png`} alt={'Green tick icon'} className={`w-8 h-8`} />
          <h4 className={`font-righteous text-xl text-brandblue`}>Asset #{showAssetAlreadyChecked} has already been checked</h4>
        </div>
        <Button
          text={'Ok'}
          onClick={() => setShowAssetAlreadyChecked(null)}
          size={'big'}
          fullwidth={true}
          variant={'primary'}
        />
      </div>
    </Modal>}


    {showUnexpectedQrCodeModal && <Modal>
      <div className={`flex flex-col gap-4`}>
        <div className={`flex flex-col gap-2`}>
          <h4 className={`font-righteous text-xl text-brandblue`}>Unexpected QR code</h4>
          {assetId && <p>Expecting QR code for asset Id: #{assetId}</p>}
        </div>
        <Button
          text={'Retry'}
          onClick={() => setShowUnexpectedQrCodeModal(false)}
          size={'big'}
          fullwidth={true}
          variant={'primary'}
        />
      </div>
    </Modal>}



    {flowState && flowState == 'PhotoTaken' && <>
    <Card>
      {jpegString && <img src={jpegString} alt={'Photo taken'} style={{ maxHeight: '50vh' }} className={``}/>}


      Enter temperature reading:
      <input
        className={`text-left font-righteous text-brandblue text-3xl bg-white text-center drop-shadow-lg border border-gray-300 rounded hover:opacity-90-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-5 py-3`}
        onChange={(e) => {
          setQuestionAnswer(e.target.value)
          setIsAnswerManuallyEdited(true)
        }}
        type={'text'}
        inputMode={/iPhone|iPad|iPod/.test(navigator.userAgent) ? 'text' :`numeric`} // the pattern below means 0 to 9, minus signs and a decimal point
        pattern={"^-?\d*(\.\d+)?$"}
        value={questionAnswer || ''}
      />

    </Card></>}

    {showFoundBanner && <>


     <PhotoInstruction >✅ QR code found.  Ready to take photo.</PhotoInstruction>

    </>}



  </div>
}
export default QuestionPhotoAndReading