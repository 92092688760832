

import React, { useContext, useState } from 'react'
import { GlobalContext } from '../GlobalContext'
import CloudformationOutputs from '../CloudformationOutputs.json'
import Modal from '../ui-elements/Modal'

type Props = {
    question: ObjectStringKeyAnyValue
    classString: string
}


type AssetNameProps = {
    assetId: string
    assetName?: string
}

function AssetName({
    assetId,
    assetName
}: AssetNameProps) {

    if (assetName) {
        return <>"{assetName}" <span className={`text-xs font-sans italic text-gray-600`}>(#{assetId})</span></>
    } else {
        return <>asset Id #{assetId}</>
    }
}


function FormatAssetName({
    question,
    classString
}: Props) {

    const {
        tableData
    } = useContext(GlobalContext)

    const assetId = question.AssetId || question.FridgeId
    const assetName = assetId && tableData && tableData.Assets && tableData.Assets[assetId] && tableData.Assets[assetId].AssetName




    if (question.AssetId && question.QuestionFormat === 'AssetReading') {
        return <p className={classString}>
            Reading for  <AssetName assetId={assetId} assetName={assetName} />
        </p>
    }
    else if (assetId && question.QuestionFormat === 'PhotoAndReading') {
        return <p className={classString}>
            Photo and reading for <AssetName assetId={assetId} assetName={assetName} />
        </p>
    }
    else if (question.FridgeId && question.QuestionFormat === 'FridgeTemperature') {
        return <p className={classString}>
            Temperature reading for <AssetName assetId={assetId} assetName={assetName} />
        </p>
    }
    else {
        return <p className={classString}>{question.QuestionText}</p>
    }

}

export default FormatAssetName