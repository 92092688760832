import React, { useEffect, useState, Children, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'

type Props = {
  percentage: number
}

function ProgressBar({
  percentage
}: Props) {

  return <div className={`w-full rounded bg-tealgreen`}>
    <div className={`${percentage < 100 && 'rounded-r'} rounded-l bg-brandblue py-2`} style={{
      width: `${percentage}%`
    }}></div>
  </div>
}
export default ProgressBar